/* eslint-disable no-undef */
export const baseUrl =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? "https://service.edencaremedical.com/prod/api/v1"
    : process.env.REACT_APP_DEMO_URL === "demo"
    ? "https://service.edencaremedical.com/demo/api/v1"
    : "https://service.edencaremedical.com/qa/api/v1";

export const titleUrl = "Provider Portal | Eden Care";

export const baseURLv2 = "https://service.edencaremedical.com/prod/api/v2";

export const token = localStorage.getItem("hr-auth-token");
